import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult4.jpg'
import restorativeTableImage from '../../assets/static/service/restorative_table.png'
import restorative2Image from '../../assets/static/service/restorative2.jpg'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import ContentSection from './components/ContentSection'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`

/** 贋復美學假牙 */
const Restorative = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.restorative' }),
      path: '/service/restorative'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.restorative' })}>
      <Seo
        title='【全瓷冠單顆14,800】關於假牙的疑難雜症-日不落牙醫集團'
        metaDescription='抽完神經的牙齒好脆弱？牙齒缺損要做牙套嗎？交給遍布台北、新竹、台中、台南的日不落牙醫集團吧！精準把關全瓷牙冠品質，不只恢復口腔功能，更提升其外觀美學，讓您在全瓷冠療程後露出滿意的微笑！'
      />
      <StyledContentTitle>全瓷假牙/{formatMessage({ id: 'service.restorative' })}</StyledContentTitle>
      <ContentSection title='假牙贋復是什麼？'>
        <p>贋復是指利用巧奪天工的人造義齒來恢復缺損的牙齒，也就是俗稱的「假牙、牙套」。藉由贋復品來修復或取代缺損的自然牙，以維持及恢復口腔功能、舒適、外觀及健康。</p>
        <br />
        <p>
          BUT，在講求美學的今天，恢復牙齒的功能性已不足以滿足大眾需求，日亞美深知大家對於美的追求，更需要的是<strong>黃金比例的外型</strong>、<strong>與閃亮潔白的齒色</strong>
          ，依照您要求的『形與色』，設計搭配陶瓷貼片或牙冠兩種服務。
        </p>
      </ContentSection>
      <ContentSection id='crown' imageUrl={restorative2Image} title='全瓷牙冠/全瓷冠美白' imageAlt='全瓷牙冠/全瓷冠美白'>
        <p>牙冠就像『全罩式安全帽』，常用於根管治療後的牙齒，因其修磨過較脆弱，便需要強壯的牙冠套在外面保護它，同時要滿足恢復咬合咀嚼功能，為常見的修復牙齒方法。</p>
        <p>常見牙冠材質包含全瓷、半瓷半金屬，及全金屬，除了考量牙齒缺損狀況、材質強度外，也會因應美觀需求選擇不同的使用材料。</p>
      </ContentSection>
      <ContentSection imageUrl={restorativeTableImage} title='牙冠材質這麼多種怎麼選？' imagePosition='fluid' imageAlt='牙冠材質比較' borderInvisible>
        <p>備註：因各種牙齒情況有其適用的牙冠材質，建議您和醫師討論，衡量自身的狀況，選擇適合自己的材質。</p>
      </ContentSection>
    </ServiceLayout>
  )
}

export default Restorative
